/**
 * Init a counter we can use to keep track of the tabs
 */
let count = 0;

/**
 * Wrap inner blocks in a tab content wrapper.
 * Make sure we look for regular blocks and WP Core group blocks.
 */
$('.js-tab-content > *').wrap(function() {
	const $this = $(this);
	const parent = $this.parents('.js-tab-content').attr('id');
	const isGrouped = $(this).parents('.wp-block-group').length ? true : false;
	const active = count === 0 ? 'show active' : '';

	// If this block is inside a group, don't count towards the index
	if ( isGrouped ) return '';

	count++;

	return `<div class="tab-pane fade ${active}" id="${parent}_tab-${count}-content" role="tabpanel" aria-labelledby="${parent}_tab-${count}"></div>`;
});

/**
 * Refresh any slick sliders that may be inside tab content.
 */
$('.js-tab-item').on('shown.bs.tab', e => {
	const $newTab = $(e.target),
		targetPanelID = $newTab.data('target');

	$(targetPanelID).find('.slick-slider').each((i, el) => {
		$(el).slick('setPosition');
	});
});
